.paddingoutline .MuiOutlinedInput-input {
    padding: 8px;
}

.roundedcorners {
    border-radius: 5px !important;
}

.textarea {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 16px;
}

.paper {
    z-index: 30;
    background: #fafafa;
}

.menuItem {
    display: flex;
    cursor: default;
    align-items: center;
}

.menuItem:hover {
    background-color: transparent;
}

.addButton {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

.addIcon:hover {
    color: #378dff;
}

.deleteButton {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}

.deleteIcon:hover {
    color: red;
}

.iconButton:disabled {
    opacity: 0.4;
}
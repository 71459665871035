.option_selector_wrapper {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 15px;
    position: relative;
}

.option_selector_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.option_selector_container_disabled {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    opacity: 38%;
}

.option_selector_title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.43;
    color: #333333;
    flex: 1;
    margin: 0;
}

.option_selector_value_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.option_selector_value_container_disabled {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}

.option_selector_value_container:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.option_selector_value {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #1A1A1A;
    margin: 0;
}

.option_selector_value_error {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: rgb(255,51,51);
    margin: 0;
}

.popper {
    position: absolute !important;
    margin: 0;
}
.dictionary_main_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}

.dictionary_content_wrapper {
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 50px 56px;
}

.dict_header_wrapper {
  width: 100%;
  height: 160px;
  min-height: 160px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #2b8dff;
  padding: 0 56px;
}
.dict_header_wrapper span {
  position: absolute;
  top: 12px;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.9);
}
.dict_header_wrapper input {
  width: 100%;
  font-family: 'Roboto';
  font-size: 64px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.77; */
  letter-spacing: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.9);
}

.dictionary_section_wrapper {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 45px;
}

.dictionary_options_line_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;
}
.main_line_section,
.dictionary_options_title {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dictionary_options_title {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.dictionary_section_main_line_divider {
  width: 100%;
  flex-shrink: 1;
  height: 1px;
  background-color: #cccccc;
  margin: 0 3px;
}

.main_command_button {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.main_command_button.phone {
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ccc;
  margin-top: 12px;
}
.main_command_button.on .command_main_color {
  fill: #2b8dff;
}
.main_command_button.on .sync_dict_leksicon {
  color: #2b8dff;
}
.main_command_button .sync_dict_leksicon {
  color: #ccc;
}
.main_command_button:hover .sync_dict_leksicon {
  color: #ffffff;
  background-color: #2b8dff;
}
.main_command_button:not(.phone):hover {
  color: #2b8dff;
}
.main_command_button.phone:hover {
  color: #ffffff;
}
.main_command_button .command_main_color {
  fill: #ccc;
}
.main_command_button:hover .command_main_color {
  fill: #ffffff;
}
.dictionary_commands_wrapper {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.contexts_lines_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 8px;
}
.context_line_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 32px;
  white-space: pre;
  /* margin-bottom: 8px; */
}
.ctx_string {
  margin: 0 auto;
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #707070;
  text-align: center;
  width: 100%;
}
.main_phrase_wrapper {
  width: 100%;
  margin: 0px;
  height: 100%;
  position: relative;
  text-align: unset;
}
.main_phrase {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.context_right_phrase {
  position: absolute;
  left: 100%;
}
.context_left_phrase {
  position: absolute;
  right: 100%;
}
.ctx_percentage {
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  margin-right: 15px;
  color: #707070;
}

.ctx_pagination_wrapper {
  width: 100%;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}
.ctx_pagination_wrapper .MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: #2b8dff !important;
  font-weight: bold !important;
  border: 1px solid #2b8dff !important;
  background-color: transparent !important;
}
.ctx_pagination_wrapper .MuiPaginationItem-root {
  color: #707070 !important;
}

.dictionary_word_line_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding-left: 8px;
}

.word_prons_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  height: 26px;
}
.word_prons_wrapper_inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* overflow: hidden; */
}

.word_pron {
  position: relative;
  width: 100%;
  white-space: pre;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #707070;
  margin-right: 44px;
}
.word_pron input {
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #707070;
}
.word_pron:hover {
  color: #000000;
}

.searched_word {
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #707070;
  margin-right: 52px;
}

.dict_popup {
  height: fit-content;
  position: absolute;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 22;
  display: flex;
  align-items: center;
}
.dict_popup.main_style {
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  background-color: rgb(43, 141, 255);
  flex-direction: row;
  padding: 6px;
  border-radius: 4px;
}
.dict_popup_arrow {
  border-right: 12px solid transparent;
  border-left: 12px solid transparent;
  border-top: 10px solid rgb(43, 141, 255);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.popup_commands_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup_commands_wrapper button {
  margin: 0 2px;
}

.phonem_helper {
  padding: 6px 12px 12px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #333;
  font-family: 'Roboto Mono';
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #ccc;
}

.phonem_helper_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 400px;
}
.phonem_helper_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.show_scrollbar::-webkit-scrollbar {
  display: flex !important;
  width: 6px;
  /* padding-bottom: 10px; */
  height: 6px;
}
/* Track */
.show_scrollbar::-webkit-scrollbar-track {
  background: #e2e2e2;
}

/* Handle */
.show_scrollbar::-webkit-scrollbar-thumb {
  background: #8a8d90;
}

.cxt_main_line_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cxt_main_line_wrapper_words {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ctx_main_percentages {
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

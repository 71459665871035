body {
  margin: 0px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  user-select: none;
  -webkit-user-drag: none;
  text-decoration: none;
}

input {
  border: none;
  background: none;
}
input:focus {
  outline: none;
  outline-offset: 0;
}
img {
  user-select: none;
  -webkit-user-drag: none;
  width: 100%;
}
textarea {
  outline: none;
  resize: none;
  border: none;
}

/* fallback for fonts added */
.home {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  height: 100vh;
  min-height: 780px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  position: relative;
  padding: 30px;
}

.home:before {
  content: '';
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  width: 56.8%;
  height: 56.8vw;
  filter: blur(5px);
  background: url(../../components/Home/logo.svg) no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.MuiFormControlLabel-root {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  justify-content: baseline;
  flex-direction: row-reverse;
  justify-content: space-between;
  font-size: 12px !important;
}
.MuiTypography-body1 {
  font-size: 12px !important;
}

/*BUTTONS*/

.buttons_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.buttons_container.invalid-auth {
  /* also need animation and -moz-animation */
  animation: shake 0.5s linear;
  -webkit-animation: shake 0.5s linear;
  -moz-animation: shake 0.5s linear;
}


.live_settings_buttons {
  display: flex;
  align-items: flex-end;
}

.button_live {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 518px;
  height: 518px;
  color: white;
  background-color: #ff6666;
  border: none;
  font-family: 'Roboto';
  font-size: 125px;
  font-weight: 900;
  cursor: pointer;
  border: none;
  outline: none;
  padding: initial;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.08),
    0px 3px 14px 2px rgba(0, 0, 0, 0.08);
  opacity: 1;
  transition: opacity 0.4s, box-shadow 0.4s, background-color 0.4s, transform 0.4s;
  overflow: hidden;
}

.disabled_live {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 518px;
  height: 518px;
  color: white;
  background-color: hsl(0deg 0% 70%);
  border: none;
  font-family: 'Roboto';
  font-size: 125px;
  font-weight: 900;
  border: none;
  outline: none;
  padding: initial;
  transition: all 0.3s ease 0s;
  overflow: hidden;
  pointer-events: none;
}

.button_live:hover > .button_live_animating_square {
  left: 600px;
}

.button_live_animating_square {
  position: absolute;
  left: -200px;
  bottom: -100px;
  width: 100px;
  height: 800px;
  background-color: #FFFFFF;
  opacity: 0.2;
  transition: left 0.4s;
  transform: rotate(345deg);
}


/*.button_live:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(204, 204, 204, 0.28);
  transform: translate(-290%, 0) skewX(25deg);

  transform-origin: top left;
  transition: all 0s;
}
.button_live:hover:before:not(:disabled) {
  transform: translate(400%, 0) skewX(25deg);
  transition: all 0.5s ease-out 0s;
}*/

/*.button_live:hover {
  background-color: #ff4e4e;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.3s ease 0s;
}*/
.button_live:active {
  box-shadow: none;
  transform: scale(0.98);
}

.button_upload {
  position: relative;
  width: 383px;
  height: 125px;
  color: white;
  background-color: #31a0ff;
  border: none;
  font-family: 'Roboto';
  font-size: 60px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  z-index: 10;
  margin-bottom: 43px;
  margin-left: -103px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.08),
    0px 3px 14px 2px rgba(0, 0, 0, 0.08);
  transition: opacity 0.4s, box-shadow 0.4s, background-color 0.4s, transform 0.4s;
  overflow: hidden;
}

.upload_disabled {
  position: relative;
  width: 383px;
  height: 125px;
  color: white;
  background-color: hsl(207.67deg 0% 59.61%);
  border: none;
  font-family: 'Roboto';
  font-size: 60px;
  font-weight: 700;
  border: none;
  cursor: auto;
  outline: none;
  padding: 0;
  z-index: 10;
  margin-bottom: 43px;
  margin-left: -103px;
  overflow: hidden;
  pointer-events: none;
}

.button_upload:hover:before {
  transform: translate(400%, 0) skewX(25deg);
  transition: all 0.3s ease-out 0s;
}
.button_upload:active {
  transform: scale(0.98);
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.button_upload:hover > .upload_button_animating_square {
  left: 600px;
}

.upload_button_animating_square {
  position: absolute;
  left: -120px;
  bottom: -40px;
  width: 100px;
  height: 200px;
  background-color: #FFFFFF;
  opacity: 0.2;
  transition: left 0.4s;
  transform: rotate(345deg);
}
.button_history {
  margin-top: 20px;
  margin-left: 302px;
  color: #707070;
  background: none;
  border: none;
  font-family: 'Roboto';
  font-size: 37px;
  font-weight: normal;
  cursor: pointer;
  border: none;
  outline: none;
  padding: initial;
  transition: all 0.5s ease 0s;
}

.button_history:hover {
  color: #505050;
  transition: color 0.5s ease-in-out 0s;
}
.button_history:active {
  transition: all 0.2s;
  transform: scale(0.98);
}

.upload_tittle_container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
}

#progress {
  width: 0px;
  height: 100%;
  transition: width 0.3s ease-in-out;
  background-color: #2192FF;
}

/*BUTTONS */

.footer_info {
  font-family: 'Roboto';
  font-size: 18px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 25px;
  margin-right: 25px;
  z-index: 30;
}

.versions_info {
  font-family: 'Roboto';
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 25px;
  white-space: pre;
}

.hidden-show-button {
  z-index: 200;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  bottom: -26px;
  left: 0;
  position: absolute;
  width: 40px;
  height: 40px;
}

.MuiPaper-rounded {
  border-radius: 0px !important;
}

.transcription_language_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.language_holder {
  display: flex;
  align-items: center;
}
.language_title {
  opacity: 0.8;
  margin-right: 35px;
}

.button_menu_settings {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 56px;
  left: 56px;
  padding: 5px;
}
.button_menu_settings svg {
  color: #cccccc;
  transition: color 250ms ease-out;
}
.button_menu_settings:hover svg {
  color: #a2a2a2;
  transition: color 250ms ease-out;
}

@media only screen and (max-width: 815px) {
  .button_live {
    width: 400px;
    height: 400px;
    font-size: 90px;
  }
  .home {
    min-height: 670px;
  }
  .button_upload {
    width: 300px;
    height: 100px;
    font-size: 45px;
    margin-left: -82px;
    margin-bottom: 38px;
  }
  .button_history {
    margin-top: 10px;
    margin-left: 254px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 630px) {
  .button_live {
    width: 350px;
    height: 350px;
    font-size: 70px;
  }
  .home {
    min-height: 590px;
  }
  .button_upload {
    width: 250px;
    height: 80px;
    font-size: 38px;
    margin-left: -68px;
    margin-bottom: 30px;
  }
  .button_history {
    margin-top: 8px;
    margin-left: 234px;
    font-size: 20px;
  }
  .button_menu_settings {
    top: 30px;
    left: 30px;
  }
}

@media only screen and (max-width: 540px) {
  .button_live {
    width: 250px;
    height: 250px;
    font-size: 45px;
  }
  .home {
    min-height: 440px;
  }
  .button_upload {
    width: 150px;
    height: 50px;
    font-size: 22px;
    margin-bottom: 15px;
    margin-left: -48px;
  }
  .button_history {
    margin-top: 10px;
    margin-left: 146px;
    font-size: 18px;
  }
  .footer_info {
    font-size: 12px;
  }
}

@media only screen and (max-width: 380px) {
  .button_live {
    width: 200px;
    height: 200px;
    font-size: 40px;
  }
  .button_upload {
    width: 120px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: -35px;
  }
  .button_history {
    margin-top: 8px;
    margin-left: 113px;
    font-size: 15px;
  }
  .footer_info {
    font-size: 10px;
  }
}

/*Custom animations*/
@keyframes shake {
  8%,
  41% {
    transform: translateX(-10px);
  }
  25%,
  58% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-5px);
  }
  92% {
    transform: translateX(5px);
  }
  0%,
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes shake {
  8%,
  41% {
    -webkit-transform: translateX(-10px);
  }
  25%,
  58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes shake {
  8%,
  41% {
    -moz-transform: translateX(-10px);
  }
  25%,
  58% {
    -moz-transform: translateX(10px);
  }
  75% {
    -moz-transform: translateX(-5px);
  }
  92% {
    -moz-transform: translateX(5px);
  }
  0%,
  100% {
    -moz-transform: translateX(0);
  }
}

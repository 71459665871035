.settings_show_scrollbar {
    overflow-y: scroll;
    margin: 0;
    height: 205px;
}

.settings_show_scrollbar::-webkit-scrollbar {
    width: 10px !important;
}

.consumption_container {
    display: flex;
    align-items: center;
}

.consumption_wrapper {
    height: 9px;
    width: 100px;
    margin-right: 5px;
}

.consumption_button_wrapper {
    height: 24px;
}

.consumption_button_image {
    height: 24px;
    width: 24px;
}

.MuiTooltip-popper {
    text-align: center;
}

.consumption_without_limit_paragraph {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    margin-right: 5px;
    text-align: right;
    color: #1A1A1A;
}
.button_secondary {
  color: #707070;
  padding: 0.5em 1em;
  font-weight: 700;
  cursor: pointer;
  font-size: 1rem;
}

.button_primary {
  background: #2b8dff;
  color: #ffffff;

  padding: 0.5em 1em;
  border-radius: 0;
  font-weight: 700;
  cursor: pointer;
  font-size: 1rem;
}

.mr_s {
  margin-right: 0.8vh;
}

.footer_btns_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 57px;
}

.lock_button {
  width: 48px !important;
  height: 48px !important;
  cursor: auto;
  color: rgb(250,102,103);
}

.footer_container {
  position: fixed;
  width: 100%;
  background-color: rgb(242, 242, 242);
  z-index: 13;
  bottom: 0px;
}

.footer_collapse_content_wrapper {
  position: relative;
}

.footer_content_wrapper {
  width: 100%;
  display: flex;
  position: relative;
  height: 100%;
  padding: 0 54px;
  height: 160px;
}

.recording_icon_wrapper_main {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.footer_commands_wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 56px;
  display: flex;
}
.footer__audio_commands_wrapper_outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footer__audio_commands_wrapper {
  display: flex;
  align-items: center;
  /* height: 80%; */
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
  justify-content: space-between;
}
.footer__audio_commands_wrapper p {
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  color: #ffffff;
  margin: 0;
}
.footer__audio_commands__sub_wrapper {
  display: inline-flex;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  height: 38px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  background: none;
  color: #ffffff;
  margin-right: 10vw;
  transition: transform 250 ease-in-out;
}
/* .footer__audio_commands__sub_wrapper:active {
  transform: scale(0.98);
  transition: transform 250 ease-in-out;
} */
.footer__audio_commands__sub_wrapper div {
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto Mono;
  color: #2b8dff;
  font-weight: bold;
  padding: 0 10px;
  margin-right: 2px;
  margin-right: 18px;
}
.footer__commands_options_wrapper {
  display: flex;
  flex-direction: column;
  height: 83%;
  justify-content: space-evenly;
}
.footer__audio_commands__sub_wrapper.spell_button div {
  width: 100%;
  /* min-width: 165px; */

  padding: 0 10px;
}

.spelled_letter {
  font-family: 'Roboto';
  font-size: 40px;
  color: #ffffff;
}

@media (max-width: 1200px) {
  .footer__audio_commands__sub_wrapper {
    font-size: 20px;
    height: 33px;
  }
}

/* @media (max-width: 1000px) {
  .footer__audio_commands__sub_wrapper {
    font-size: 16px;
    height: 30px;
  }
} */

@media (max-width: 660px) {
  .footer_content_wrapper {
    padding-right: 29px;
    padding-left: 29px;
    height: 120px;
  }
}

@media (max-width: 560px) {
  .footer_commands_wrapper {
    right: 25px;
  }
}

@media (max-width: 480px) {
  .recording_icon_wrapper_main,
  .footer_commands_wrapper {
    position: unset;
    transform: unset;
  }
  .footer_content_wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
  }
}

.custom-snackbar {
    display: flex;
    align-items: center;
    padding: 6px 20px;
    margin: 8px;
    border-radius: 4px;
    /* Text color */
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: #fff;
}

.custom-snackbar-icon {
    cursor: pointer;
    margin-left: 12px;
}

.custom-snackbar.error:hover {
    background-color: #c9342a;
}
.custom-snackbar.error {
    background-color: #d32f2e;
    /* Error color (red) */
}

.custom-snackbar-span {
    padding: 8px 0;
}

.close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: auto;
    /* Push the close button to the right */
    font-size: 16px;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100%;
  margin: 0;
}

.MuiLinearProgress-colorSecondary {
  background-color: #c8e1ff51 !important;
}
.MuiLinearProgress-barColorSecondary {
  background-color: #2196f3 !important;
}

.MuiLinearProgress-root {
  height: 10px !important;
  z-index: 20;
}

main {
  height: 100vh;
}

article {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto 1fr;
  height: 100%;
}

/* Applied filters */
.applied-filters {
  background-color: #F7F7F7;
}
.applied-filters-row {
  display: flex;

  gap: 1rem;
  padding-top: 23px;
  padding-bottom: 39px;
  padding-left: 10px;
}

.applied-filters-inner-wrapper {
  align-items: center;
  gap: 1rem;
  display: flex;
  overflow-x: auto !important;
  overflow-y: hidden;
}

.applied-filter-item {
  flex-shrink: 0;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.322);
  height: 30px;
}

.applied-filter-item>span {
  padding: 0 5px;
}

.applied-filter-remove {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.applied-filter-remove:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.applied-filters-row-tag {
  font-family: 'Roboto';
  font-weight: 500;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

/* ----- Srollbar za filter (zlo redko, ma za vsak slucaj) */

.applied-filters-inner-wrapper::-webkit-scrollbar {
  height: 3px;
  display: block;
}

.applied-filters-inner-wrapper::-webkit-scrollbar-track {
  background: #cccccc;
}

.applied-filters-inner-wrapper::-webkit-scrollbar-thumb {
  background: #005173;
}

.applied-filters-inner-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ------------------------------ Pagination */
.pagination-wrapper {
  background-color: #E6E6E6;
  height: 75px;
  flex-shrink: 0;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ------------------------------ Tabela */

.table-container {
  width: 100%;
  position: relative;
  padding-left: 5%;
  background-color: #F7F7F7;
}

.table-wrapper {
  align-items: flex-start;
  flex-grow: 1;
  margin: auto;
  margin-top: 35px;
  display: flex;
  overflow-x: auto;
  flex-direction: column;
  padding-bottom: 10px;
}

.row {
  display: flex;

  width: fit-content;
  min-width: 100%;

  position: relative;

  padding: 5px 0px 5px 15px;

  cursor: pointer;


  border-bottom: 1px solid rgba(146, 146, 146, 0.438);
  border-left: 3px solid #62a9fb00;
  transition: border 60ms ease-out;
  transition: background-color 150ms ease-out;
}

.row:hover {
  background-color: #f2f2f2;
  opacity: 0.9;
}

.row-inner-wrapper {
  min-width: fit-content;
  position: relative;

  overflow-y: hidden;
  height: 28px;
}

.row-details-header {
  position: absolute;
  left: 0%;
  right: 0%;

  top: -100%;

  transition: all 0.15s ease-in-out !important;

  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: #457ab6;

  display: flex;
  align-items: center;

  padding-top: 6px;

  opacity: 0;

  height: 100%;

  z-index: 1;
}

.row-details-header.active {
  top: 0%;

  opacity: 1;
}

.row-attribute-wrapper {
  position: relative;

  display: flex;
  opacity: 1;
  transition: all 0.15s ease-in-out !important;
  overflow: hidden;

  min-width: 100%;
  width: fit-content;
}

.row-attribute-wrapper.hidden {
  opacity: 0;
}

.row-extender-icon-spacer {
  width: 40px;
}

.row-extender-icon-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
}

.row-individual-attribute {
  font-family: Roboto;
  font-weight: 400;
  font-size: 16px;

  height: 28px;

  line-height: 200%;

  /* position: relative; */

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-extender-icon {
  cursor: pointer;
  z-index: -1;
  padding: 3px;
  transform: rotate(270deg);
  transition: all 0.35s ease-in-out !important;
  color: black;
}

.row-extender-icon.extended {
  transform: rotate(360deg);
}

/* When the accordion is opened */
.row-active {
  border-left: 3px solid #62a9fb;
}

.row-attr-labels {
  display: flex;
  align-items: center;
}

.row-attr-labels>* {
  margin-right: 8px !important;
}

/* ------------------------------ Table header */

.header-item-resizable-handle {
  width: 15px;
  height: 100%;
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}

.header-item-resizable-handle:hover {
  opacity: 1;
}

.header-item-resizable-handle>div {
  width: 2px;
  height: 60%;
  background-color: rgba(63, 63, 63, 0.5);
  cursor: col-resize;
}

.header-item-controls {
  min-width: min-content;
  flex-grow: 1;
  display: flex;
  direction: row;
  align-items: center;
}

.table-header {
  display: flex;
  padding: 4px 0;
  padding-left: 18px;
  min-width: 100%;
  width: fit-content;

  position: sticky;
  top: 0;
  z-index: 3;
}

.table-header-item-wrapper {
  display: flex;
  height: 30px;
}

.table-header-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: move;
  position: relative;
  border-bottom: 1px solid #00000000;
}

.table-header-item-dragging {
  border-bottom: 2px solid #fc8831;
  z-index: 33;
}

.table-header-item-over {
  border-bottom: 1px solid rgba(39, 39, 39, 0.1);
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.table-header-label {
  height: 24px;
  padding-top: 2px;
  user-select: none;
  margin-right: 3px;

  font: 400 16px 'Roboto';

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-header-icon-filter {
  color: rgba(62, 107, 165, 0.712);
  max-height: 24px;
  margin: 0px;
  padding-bottom: 2px;
}

.table-header-icon {
  color: rgba(0, 0, 0, 0.712);
  cursor: pointer;
  max-height: 24px;
  margin: 0px;
  padding-bottom: 2px;
}

.table-header-icon:hover {
  color: rgba(39, 39, 39, 0.274);
}

/* ------------------------------ Srollbar v Viewer tabeli */


/* Accordion */

.accordion-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.accordion-header-icon {
  color: rgba(0, 0, 0, 0.63);
}

.accordion-header-icon:hover {
  cursor: pointer;
  color: #2b8dff;
}

.accordion-header-delete-icon:hover {
  color: red;
}

.accordion-body {
  display: flex;
  direction: column;
}

.accordion-chip-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  direction: row;
}

.accordion-chip-container>* {
  margin-right: 8px !important;
}

.accordion-chip-popper-container {
  padding: 0px;
}

.accordion-chip-popper-wrapper {
  max-height: 350px;
  overflow-y: scroll;
}
.accordion-chip-popper-footer {
  display: flex;
  direction: row;
  align-items: center;
  padding: 5px;
}

.accordion-chip-popper-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
}

.accordion-chip-popper-footer-add {
  padding: 2px;
  margin: 0 6px;
}

.accordion-body-container {
  padding: 10px 0;
  padding-bottom: 20px;
  margin-right: 30px;
  max-width: '600px';
  width: 100%;
  word-break: break-all;
}

.accordion-body-container>p {
  margin-top: 0px;
}

.session-metadata {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  padding-top: 0px;
}

.session-metadata>* {
  margin-right: 6px;
}

.accordion-body-sub-headers {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.accordion-footer {
  flex-shrink: 0;
  padding-top: 15px;

  box-sizing: border-box;
  width: 100%;
  display: flex !important;
  justify-content: space-between;
}

.row-accordion-wrapper {
  flex-shrink: 0;
  min-width: 100%;

  background-color: #5d60ff0a;
}

.row-accordion {
  min-height: max-content;

  padding: 20px;
  display: flex;
  height: 100%;
  border-left: 3px solid #62a9fb;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.row-button-editor {
  border: none;
  outline: none;
  transition: transform 250ms ease-in-out;
  margin: 0;
  user-select: none !important;
  background: #2b8dff;
  color: #ffffff;
  padding: 0.5em 1em;
  border-radius: 0;
  font-weight: 700;
  font-size: 1rem;
  position: relative;
  cursor: pointer;
}

/* ----- Media Query */

@media only screen and (max-width: 1024px) {
  .table-container {
    width: 95vw;
  }
}





/*TEST*/
table {
  border-collapse: collapse;
}
table,
.divTable {
  width: fit-content;
}

.tr {
  display: flex;
}

/*tr,
.tr {
  width: fit-content;
  height: 30px;
  border-spacing: 0;
  border-bottom: 1px solid #CCCCCC;
}*/

/*th,
.td {
  border-spacing: 0;
}*/

p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*th {
  position: relative;
  font-weight: bold;
  text-align: left;
  height: 30px;
  border-bottom: 3px solid #707070;
}*/

/*td,
.td {
  position: relative;
  max-width: 0;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
}*/

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 28px;
  background: rgba(0, 0, 0, 0);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

/*.resizer.isResizing {
  background: blue;
  opacity: 1;
}*/

.resizer.isResizing.cellRow {
  border-right: 1px solid black;
}

.cellRow {
  padding: 0;
}

.cellRowExpanded {
  background-color: #707070;
  padding: 0;
}

.bottom-right-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.bottom-left-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 14px;
}

.MuiSvgIcon-colorSecondary {
  color: #707070 !important;
}

/*@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
*/


.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;

  bottom: 100%;
  left: 50%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.table-header-paragraph {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  font-family: 'Roboto';
}

.right-aligned-table-header-paragraph {
  text-align: right;
  padding-right: 28px;
}

.statusRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  height: 100%;
}

.table-row:hover {
  cursor: pointer;
}

.statusRowParagraph {
  font-size: 18px;
  line-height: 21px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.statusRowParagraphError {
  color: #000000;
}
.statusRowParagraphDeleted {
  color: #F80534;
}
.statusRowParagraphEditing {
  color: #FC8831;
}
.statusRowParagraphTranscribing {
  color: #404040;
}
.statusRowParagraphEdit {
  color: #FFFFFF;
}
.statusRowParagraphUploading {
  color: #BFBFBF;
}
.statusRowParagraphInQueue {
  color: #808080;
}

.extended-row-propery-title {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Roboto';
  color: #707070;
}

.chips-wrapper {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.summary-wrapper {
  width: 820px;
  max-height: 100px;
}
.summary-box {
  background: #D9D9D9;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 10px;
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  width: 820px;
}

.pagination-previous {
  margin-left: 16px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-next {
  margin-left: 8px;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-page:hover {
  cursor: pointer;
}
.pagination-last:hover {
  cursor: pointer;
}
.pagination-next:hover {
  cursor: pointer;
}
.pagination-first:hover {
  cursor: pointer;
}
.pagination-previous:hover {
  cursor: pointer;
}

.disabled-pagination-button {
  opacity:  0.2;
}

.disabled-pagination-button:hover {
  cursor:auto;
}

.pagination-page {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-page-selected {
  background-color: #2B8DFF;
}

.pagination-page-not-selected {
  background-color: transparent;
}

.pagination-first {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-last {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-icon {
  width: 12px;
  height: 12px;
}

.pagination-page-text-selected {
  font-weight: 700;
  color: #FFFFFF;
}

.pagination-page-text-not-selected {
  font-weight: 700;
  color: #707070;
}

.recordedSeconds-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.createdAt-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.updatedAt-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.recordedSeconds-cell-paragraph {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.recordedSeconds-cell-paragraph-expanded {
  color: #E6E6E6;
}

.recordedSeconds-cell-paragraph-not-expanded {
  color: #707070;
}

.createdAt-cell-paragraph {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.createdAt-cell-paragraph-expanded {
  color: #E6E6E6;
}

.createdAt-cell-paragraph-not-expanded {
  color: #707070;
}

.updatedAt-cell-paragraph {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.updatedAt-cell-paragraph-expanded {
  color: #E6E6E6;
}

.updatedAt-cell-paragraph-not-expanded {
  color: #707070;
}

.createdBy-cell-paragraph {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
}

.createdBy-cell-paragraph-expanded {
  color: #E6E6E6;
}

.createdBy-cell-paragraph-not-expanded {
  color: #707070;
}

.resize-anchor-div {
  width: 28px;
  height: 100%;
}


.resize-anchor-div-expanded {
  background-color: #707070;
}

.name-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.visibility-cell-paragraph {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}

.visibility-cell-paragraph-expanded {
  color: #FFFFFF;
}
.visibility-cell-paragraph-not-expanded {
  color: #707070;
}

.name-cell-paragraph {
  font-weight: 700;
  font-size: 18px;
  margin: 0;
}
.name-cell-paragraph-expanded {
  color: #FFFFFF;
}
.name-cell-paragraph-not-expanded {
  color: #707070;
}

.visibility-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-cell-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-cell-content-container {
  display: flex;
  flex-direction: row;
}

.label-paragraph {
  margin: 0;
  font-size: 18px;
}

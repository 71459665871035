.react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: col-resize;
    z-index: 1;
    background: url('../../../public/resize_icon.svg') no-repeat center; 
  }
  
  .row-resizer {
    opacity: 0;
  }

  .row-header {
    height: 30px;
    filter: blur(0px);
    transition: filter 0.5s ease;
    padding: 12px 0px;
    color: #707070;
    border-bottom: 2px solid #CCCCCC;
  }
  
  .row-header-deleted {
    height: 30px;
    filter: blur(0px);
    transition: filter 0.5s ease;
    padding: 12px 0px;
    color: #707070;
    border-bottom: 2px solid #CCCCCC;
  }

  .selected-row-header {
    background-color: #707070;
    color: #FFFFFF;
  }

  .blurred-row-header {
    filter: blur(4px);
    user-select: none;
  }
  
  #table_head {
    position: sticky;
    top: 0;
    z-index: 3;
    box-shadow: 0px 3px 0px #707070;
  }
  
  #table {
    border-collapse: collapse;
  }
  
  .statusRowParagraphError {
    color: #000000;
  }
  .statusRowParagraphDeleted {
    color: #F80534;
  }
  .statusRowParagraphEditing {
    color: #FC8831;
  }
  .statusRowParagraphTranscribing {
    color: #404040;
  }
  .statusRowParagraphEdit {
    color: #FFFFFF;
  }
  .statusRowParagraphUploading {
    color: #BFBFBF;
  }
  .statusRowParagraphInQueue {
    color: #808080;
  }

  .statusRowContainerError {
    background-color: #F8EE05;
  }
  
  .statusRowContainerEdit {
    background-color: #2B8DFF;
  }

  .statusRowBasic {
    background-color: #FAFAFA;
  }

  .resizing-row {
    background-color: #2B8DFF40 !important;
    border-right: 1px dotted #2B8DFF;
    border-left: 1px solid #2B8DFF;
  }

  .resizing-header {
    border-right: 1px dotted #2B8DFF;
    border-left: 1px solid #2B8DFF;
  }

  .row-body {
    box-sizing: border-box;
    background-color: #E6E6E6;
    box-shadow: inset 2px 0 0 0 #707070, inset -2px 0 0 0 #707070;
  }

  .row-body-content {
    padding: 35px 17px 32px 17px;
  }

  .row-body-content-session-info {
    display: flex;
  }

  .preview-notes-container {
    flex: 1;
  }

  .recordings-table-wrapper {
    flex: 1;
  }
  
  .preview-recording-container {
    display: flex;
  }

  .preview-header {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
    margin: 0 0 8px 0;
  }

  .preview-contents {
    background: #D9D9D9;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    width: 95%;
    padding: 6px 9px;
  }

  .preview-contents-text {
    color: #707070;
    font-family: Roboto Mono;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    white-space: normal;
    margin: 0;
  }

  .notes-header {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
    margin: 0 0 8px 0;
  }

  .notes-textarea {
    background: #D9D9D9;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    padding: 6px 9px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    font-style: italic;
    color: #707070;
    width: 100%;
    overflow-y: scroll;
  }
  .notes-container {
    width: 95%;
    margin-top: 17px;
  }
  .notes-input::placeholder {
    font-style: italic;
  }

  .row-body-toolbar {
    background-color: #CCCCCC;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
  }
  
  td {
    padding: 0px;
  }
  
  th {
    padding: 0px;
  }

  #containers_container {
    padding: 0 60px;
    overflow: auto;
    flex-grow: 1;
    background-color: #F7F7F7;
  }

  .container {
    margin: 0 auto;
    width: fit-content;
    
    /*overflow-x: auto;*/
  }

  .recordings-table {
    width: 100%;
    border-spacing: 4px;
  }
  .recordings-table-thead {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
    
  }

  .recordings-table-tbody {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
  }


  .right-aligned-recording-table-header {
    padding-bottom: 8px !important;
    text-align: right;
  }
  .left-aligned-recording-table-header {
    padding-bottom: 8px !important;
    text-align: left;
  }

  .recordings-table-header-cell {
    padding: 0 4px;
  }

  .recordings-table-cell {
    padding: 0 4px;
  }

  .manage-labels-button {
    background: url('../../../public/add_more.svg') no-repeat center;
    height: 24px !important;
    width: 24px !important;
  }
  .manage-labels-button-expanded {
    background: url('../../../public/add_more_inverse.svg') no-repeat center;
    height: 24px !important;
    width: 24px !important;
  }

  .download-button {
    background: url('../../../public/download.svg');
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
  .share-button {
    background: url('../../../public/share.svg');
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  .recreate-button {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  .cancel-session-button {
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  .copy-button {
    background: url('../../../public/content_copy.svg');
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }
  .delete-button {
    background: url('../../../public/delete.svg');
    height: 24px;
    width: 24px;
    margin-right: 5px;
  }

  .sorting-desc-icon {
    background: url('../../../public/filter_dsc.svg');
    height: 20px;
    width: 18px;
    margin-left: 5px;
  }
  .sorting-asc-icon {
    background: url('../../../public/filter_dsc.svg');
    height: 20px;
    width: 18px;
    transform: rotate(180deg);
    margin-left: 5px;
  }
  .open-button {
    height: 30px;
    width: 80px;
    background-color: #2B8DFF !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /*background-color: #2B8DFF;*/
  }

  .open-button:hover {
    cursor: pointer;
  }

  .open-button > p {
    margin: 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
  }

  .labels-container-wrapper {
    display: flex;
    flex-direction: row;
    height: 32px;
    align-items: center;
  }

  .labels-container {
    width: 95%;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    overflow-y: scroll;
    max-height: 100px;
  }

  .label-wrapper {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  .delete-label-button {
    margin-left: 4px;
    height: 24px;
    width: 24px;
  }

  .label-button-text {
    user-select: none;
    font-weight: 600;
    font-size: 14;
    margin: 0;
  }

  .label-button-text:hover {
    cursor: pointer;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 999999;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .version-option:hover {
    background-color: #D9D9D9 !important;
  }
  .version-option {
    padding: 0 23px 0 23px !important;
    height: 26px;
  }

  .version-option-date {
    margin: 0;
  }
  .version-option-name {
    margin: 0;
  }

  #date-picker-container {
    margin-left: 112px;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }

  .date_input {
    text-align: center;
  }

